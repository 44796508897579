import request from "@/common/request";

export function getList(categoryId,pageNum, pageSize) {
  const data = {
    categoryId: categoryId,
    pageNum: pageNum,
    pageSize: pageSize
  };
  console.log('param: ', data)
  return request({
    url: "/app/product/list",
    method: "post",
    data: JSON.stringify(data)
  });
}

export function getDetail(id) {
  return request({
    url: "/app/product/detail/" + id,
    method: "post"
  });
}

export function getCategory() {
  return request({
    url: "app/v2/product/categorys",
    method: "get"
  });
}

