<template>
  <div style="height: 100%;  background-color: #f8f8f8;">
    <van-nav-bar left-arrow title="创联商城" @click-left="goNavBack" fixed/>
    <div style="margin-top: 46px">
      <van-tabs v-model="tabIndex" animated @click="onTabClick" >
        <van-tab :key="item.id" v-for="item in categoryList" :title="item.categoryName">
        </van-tab>
      </van-tabs>
    </div>
    <div class="product">

      <van-list
          :immediate-check="false"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          :error.sync="error"
          @load="onLoad"
          error-text="请求失败，点击重新加载"
      >
        <van-row>
          <van-col
              span="12"
              class="product-item"
              v-for="item in list"
              :key="item.id"
              @click="productDetail(item)"
          >
            <div class="item-detail">
              <div class="product-img">
                <img :src="item.mainImg" alt/>
              </div>
              <div class="content">
                <div class="title">{{ item.title }}</div>
                <div class="sales">销量： {{ item.sales || 0 }}</div>
                <div class="price">¥ {{ item.price }}</div>
              </div>
            </div>
          </van-col>
        </van-row>
      </van-list>
    </div>
  </div>
</template>

<script>
import {getList, getCategory} from "@/api/product";
import {goNavBack} from "@/common/utils";
// 商品列表
export default {
  name: "ProductList",
  data() {
    return {
      pageNum: 1,
      pageSize: 20,
      pages: 0,
      total: 0,
      list: [],
      finished: false, // 标识是否最后一页
      error: false, // 错误状态
      loading: false, // 加载状态
      goNavBack: goNavBack,
      categoryList: [],// 分类列表
      categoryId: null, // 分类ID
      tabIndex: 0, // tab索引
    };
  },
  created() {
    this.onLoad();
    getCategory().then((res) => {
      this.categoryList = [];
      this.categoryList.push({id: null, categoryName: '全部'})
      res.data.map(item => this.categoryList.push(item))
    });
  },
  activated() {
    // this.onLoad();
  },
  mounted() {

    // if (window.history && window.history.pushState) {
    //   history.pushState(null, null, document.URL);
    //   window.addEventListener(
    //       "popstate",
    //       () => {
    //         console.log("asdsad");
    //         this.pageNum = 1;
    //         this.loadData();
    //
    //       },
    //       false
    //   );
    // }
  },
  methods: {
    onLoad() {
      const timer = setTimeout(() => {
        // 若加载条到了底部
        this.load(); // 调用上面方法,请求数据
        this.pageNum++; // 分页数加一
        this.finished && clearTimeout(timer); // 清除计时器
      }, 100);

      // console.log("this.list =", this.list.length);
    },
    async loadData(init) {
      console.log('aaa')
      const {data} = await getList(this.pageNum, this.pageSize);
      console.log("data = ", data);
      if (data.records === 0) {
        this.list = []; // 清空数组
        this.finished = true; // 停止加载
      }

      this.total = data.total;
      this.list.push(...data.records);
      this.loading = false;
      if (this.list.length >= data.total) {
        this.finished = true;
      }
    },
    load() {
      // this.pageNum++;
      // const { data } = await getList(this.pageNum, this.pageSize);
      getList(this.categoryId, this.pageNum, this.pageSize).then(res => {
        const {data} = res;
        console.log("data = ", data);
        this.total = data.total;
        if (this.list.length >= data.total) {
          this.finished = true;
          return;
        }
        this.list.push(...data.records);
        // 加载状态结束
        this.loading = false;
      });
      // setTimeout(() => (this.loading = false), 1000);a
    },
    test() {
      console.log("haha");
    },
    productDetail(item) {
      // alert("哈哈哈");
      this.$router.push({path: `/mall/detail/${item.id}`});
    },
    onTabClick() {
      // console.log("tabIndex = ", this.tabIndex);
      this.categoryId = this.categoryList[this.tabIndex].id;
      // console.log("this.categoryId = ", this.categoryId);
      this.pageNum = 1;
      this.list = [];
      this.load(); // 调用上面方法,请求数据
    }
  }
};
</script>
<style lang="scss">
.product {
  background-color: #f8f8f8;
  padding: 0 10px;
  height: 100%;
  //margin-top: 46px;

  .product-item {
    // height: 220px;
    background-color: #fff;
    margin-top: 20px;
  }

  .item-detail {
    width: 100%;
  }

  .product-img {
    width: 100%;

    img {
      width: 100%;
    }
  }

  .product-item:nth-child(odd) {
    margin-right: 2%;
  }

  .van-col--12 {
    width: 49%;
  }

  .content {
    padding: 0 10px;

    .title {
      margin-bottom: 10px;
      width: 100%;
      height: 46px;
      max-height: 46px;
      white-space: pre-wrap;
      //text-overflow: ellipsis;
      //-o-text-overflow: ellipsis;
      overflow: hidden;
      font-size: 15px;
    }

    .sales {
      font-size: 14px;
      //color: #e1e1e1;
      margin-bottom: 5px;
    }

    .price {
      color: red;
      margin-bottom: 30px;
    }
  }
}
</style>
